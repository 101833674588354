export const getOnlyDigits = (string) => {
  return string.replace(/\D/g, "")
}

export const getOnlyAreaCode = (string) => {
  const areaCode = string.substring(
    string.lastIndexOf("(") + 1,
    string.lastIndexOf(")")
  )
  return areaCode
}

export const getOnlyPhoneNumber = (string) => {
  const removeAreaCode = string.replace(/ *\([^)]*\) */g, "")
  const onlyDigitsValue = getOnlyDigits(removeAreaCode)
  return onlyDigitsValue
}

export const getDayFromDate = (string) => {
  return string.substring(0, 2)
}

export const getMonthFromDate = (string) => {
  return string.substring(string.indexOf("/") + 1, string.lastIndexOf("/"))
}

export const getYearFromDate = (string) => {
  return string.substring(string.lastIndexOf("/") + 1)
}

export const minTwoDigits = (n) => {
  return (Number(n) < 10 ? "0" : "") + n
}

export const maskPhone = (v) => {
  v = v.replace(/\D/g, "") //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2") //Coloca hífen entre o quarto e o quinto dígitos
  return v
}

export const capitalizeTheFirstLetterOfEachWord = (s) => {
  if (!s) return ""

  let separateWord = s.toLowerCase().split(" ")

  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }

  return separateWord.join(" ")
}

export const getResultText = (result) => {
  return `${result} resultado${result === 1 ? "" : "s"}`
}
