import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Input } from "antd"
import InputMask from "react-input-mask"
import _get from "lodash/get"

import CustomProTable from "@components/CustomProTable"
import { LayoutAdmin } from "@components/LayoutAdmin"
import SwitchStatus from "@components/SwitchStatus"
import { useStores } from "@hooks/use-stores"
import { formatDate } from "@utils/date"

const Subscribers = () => {
  const [isClient, setIsClient] = useState(false)
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState([])

  const actionRef = useRef(null)

  const { registrationAffiliated, registrationSubscriber } = useStores()
  const { findAllAdmin } = registrationAffiliated
  const { updateSubscriberAdmin } = registrationSubscriber

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleFindAllSubscribers = useCallback(async (params) => {
    const response = await findAllAdmin(params)

    const newResponse = _get(response, "data", [])
    const total = _get(response, "total", [])

    newResponse.sort((a, b) => b.id - a.id)

    setData(newResponse)
    setTotalData(total)
  }, [])

  const handleChangeRecordStatus = async ({ newStatus, record }) => {
    const { id } = record

    await updateSubscriberAdmin(id, { status: newStatus })
    await handleFindAllSubscribers({ current: 1, pageSize: 10 })
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: "drag-visible",
      key: "id",
      align: "left",
      width: "120px",
      render: (text) => <label className="normal pointer nowrap">{text}</label>,
      renderFormItem: () => {
        return (
          <Form.Item name="id">
            <InputMask
              mask="999999999999999"
              maskChar={null}
              className="input-general"
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  placeholder="Informe o filtro"
                  style={{ fontFamily: "poppins" }}
                />
              )}
            </InputMask>
          </Form.Item>
        )
      },
    },
    {
      title: "Cadastro",
      dataIndex: "createdDate",
      className: "drag-visible",
      key: "createdDate",
      hideInSearch: true,
      align: "left",
      width: "350px",
      render: (text) => (
        <label
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {formatDate(text)}
        </label>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      className: "drag-visible",
      key: "name",
      align: "left",
      width: "350px",
      render: (text) => (
        <label
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {text}
        </label>
      ),
    },
    {
      title: "Apelido",
      dataIndex: "nickname",
      className: "drag-visible",
      key: "nickname",
      align: "left",
      width: "350px",
      render: (text) => (
        <label
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {text}
        </label>
      ),
    },
    {
      title: "Nome de usuário",
      dataIndex: "username",
      className: "drag-visible",
      key: "username",
      align: "left",
      width: "350px",
      render: (text) => (
        <label
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {text}
        </label>
      ),
    },
    {
      title: "CPF",
      dataIndex: "documentNumber",
      className: "drag-visible",
      key: "documentNumber",
      align: "left",
      width: "250px",
      render: (text) => (
        <label
          style={{ minWidth: "fit-content!important" }}
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {text}
        </label>
      ),
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      className: "drag-visible",
      key: "phone",
      align: "left",
      width: "250px",
      render: (text) => (
        <label
          className="normal pointer nowrap"
          // onClick={() => handleEditSubscriber()}
        >
          {text}
        </label>
      ),
      renderFormItem: () => {
        return (
          <Form.Item name="phone">
            <InputMask
              mask="9999999999999"
              maskChar={null}
              className="input-general"
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  placeholder="Informe o filtro"
                  style={{ fontFamily: "poppins" }}
                />
              )}
            </InputMask>
          </Form.Item>
        )
      },
    },

    {
      title: "Status",
      dataIndex: "",
      className: "drag-visible",
      key: "status",
      align: "center",
      width: "70px",
      render: (_, record) => (
        <SwitchStatus
          record={record}
          handleChangeRecordStatus={handleChangeRecordStatus}
        />
      ),
      onFilter: (value, record) => record.status === value,
    },
  ]

  if (!isClient) return null

  return (
    <LayoutAdmin
      title="Assinantes"
      subTitle="Visualize e gerencie os assinantes."
    >
      <Col span={24}>
        <div className="subscribers-table">
          <CustomProTable
            actionRef={actionRef}
            headerTitle="Listagem de assinantes"
            columns={columns}
            dataSource={data}
            request={handleFindAllSubscribers}
            toolBarRender={() => [
              <Button key="button" type="primary">
                Cadastrar assinante
              </Button>,
            ]}
            rowKey={(record) => record.id}
            pagination={{
              total: totalData,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showTotal: (total) => {
                if (total > 0) {
                  return `Total ${total} ${total > 1 ? "itens" : "item"}`
                }

                return null
              },
            }}
          />
        </div>
      </Col>
    </LayoutAdmin>
  )
}

export default Subscribers
