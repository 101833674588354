import "dayjs/locale/pt-br"

import { PaymentMethodEnum } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import dayjs from "dayjs"
import moment from "moment"

const WeekDays = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

const relativeTime = require("dayjs/plugin/relativeTime")
const updateLocale = require("dayjs/plugin/updateLocale")

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale("pt", {
  relativeTime: {
    past: "%s atrás",
  },
})

export const hoursDiff = (date, locale = "pt-br", suffix = true) => {
  return `${dayjs(date).locale(locale).fromNow(suffix)}`
}

export const getExpirationBill = (daysToExpire = 5) => {
  let expirationDate = dayjs().add(daysToExpire, "days")

  const expirationDateWeekDay = dayjs(expirationDate).day()

  switch (expirationDateWeekDay) {
    case WeekDays.SATURDAY:
      expirationDate = expirationDate.add(2, "days")
      break

    case WeekDays.SUNDAY:
      expirationDate = expirationDate.add(1, "days")
      break
  }

  return expirationDate.format("DD/MM/YYYY")
}

export const getNextInvoiceDateMessage = (month, paymentMethod) => {
  if (paymentMethod === PaymentMethodEnum.BILLET) {
    return "paymentStatusDescriptionBillet"
  }

  const actualMonth = dayjs().get("month") + 1

  if (Number(month) - actualMonth === 1) {
    return "paymentStatusDescriptionMonthly"
  }

  return "paymentStatusDescription"
}

export const isValid = (date, format) =>
  format ? dayjs(date, format).isValid() : dayjs(date).isValid()

export const getTimeHumanized = (createdDate) => {
  const today = moment()
  const someday = moment(createdDate)
  moment.updateLocale("pt", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "agora",
      ss: "%ds",
      m: "1min",
      mm: "%dmin",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      w: "1s",
      ww: "%ds",
      M: "1 mês",
      MM: "%d meses",
      y: "1 ano",
      yy: "%d anos",
    },
  })

  let finalDiff = today.diff(someday, "minutes")
  let type = "minutes"

  if (finalDiff > 43200) {
    finalDiff = today.diff(someday, "months")
    type = "months"
  } else if (finalDiff > 1440) {
    finalDiff = today.diff(someday, "days")
    type = "days"
  } else if (finalDiff > 60) {
    finalDiff = today.diff(someday, "hours")
    type = "hours"
  }
  // console.log(moment().subtract(finalDiff, type).fromNow())
  return moment().subtract(finalDiff, type).fromNow()
}

export const formatDate = (date) => {
  if (!date || date === "-") return "-"

  return dayjs(date).format("DD/MM/YYYY")
}
