import React from "react"
import _get from "lodash/get"
import { Switch } from "antd"

import { DialogModal, DialogTypes } from "@components/DialogModal"
import { SubscriberStatus } from "@modules/Registration/store"

const SwitchStatus = ({ record, handleChangeRecordStatus }) => {
  const [currentStatus, setCurrentStatus] = React.useState(
    _get(record, "status")
  )

  const [visible, setVisible] = React.useState(false)

  const handleChangeStatus = async () => {
    const newStatus =
      currentStatus !== SubscriberStatus.ACTIVE
        ? SubscriberStatus.ACTIVE
        : SubscriberStatus.OPENED

    setCurrentStatus(newStatus)
    await handleChangeRecordStatus({
      newStatus,
      record,
    })
    setVisible(false)
  }

  const dialogContent = `Clique no botão confimar se você deseja alterar o staus do user para ${
    currentStatus === SubscriberStatus.ACTIVE ? "Inativo" : "Ativo"
  }`
  return (
    <div className="flex flex-row">
      <Switch
        onChange={() => setVisible(true)}
        checked={currentStatus === SubscriberStatus.ACTIVE}
      />
      <DialogModal
        dialog
        dialogType={DialogTypes.CONFIRM}
        visible={visible}
        title="Deseja alterar este status?"
        dialogContent={dialogContent}
        onCancel={() => setVisible(false)}
        onOk={handleChangeStatus}
        okText="Confirmar"
        cancelText="Cancelar"
      />
    </div>
  )
}
export default SwitchStatus
